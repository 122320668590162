
<template>
  <div class="audit auditWarp" v-if="pageshow">
    <div class="top">
      <img :src="siteBg" class="sitebg-img">
      <div class="top-site">
        <div class="top-wrap">
          <div class="station_name">{{pendingInfo.stationName}}</div>
          <div class="station_dept">所属部门：<span>{{pendingInfo.deptName}}</span></div>
          <div class="station_dept">合作业务：<span>{{pendingInfo.coopBiz}}</span></div>
          <div class="station_dept">推荐来源：<span>{{pendingInfo.recommendSource}}</span></div>
          <div class="station_dept" v-if="pendingInfo.coopBizCode == 'AGRI_FINANCE'">合作银行：<span>{{pendingInfo.cooperationBank}}</span></div>
          <div class="station_dept" >是否有失信记录：<span>{{isSXR}}</span></div>
          <img :src="kaocha" class="kaocha-img">
          <div class="seeBtn"  @click="siteInfoClick">查看站点</div>
        </div>
        <div class="bank-wrap">
          <div class="flex">
            <div class="left">编号：{{pendingInfo.areaNo}}</div>
            <div class="right">管理责任人：{{pendingInfo.marketerName}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div  >
      <header-site-info bg-color="#63B57E" :siteName="pendingInfo.stationName" :siteNo="pendingInfo.areaNo" :siteDepart="pendingInfo.deptName ? pendingInfo.deptName : pendingInfo.stationDept"></header-site-info>
    </div> -->
    <!-- <div class="content-title">签约信息</div>
    <div class="content">
      <van-field label="站点状态" :value="pendingInfo.stationStatus" readonly input-align="right"/>
      <van-field label="站点管理责任人" label-width="150px" :value="pendingInfo.marketerName" readonly input-align="right"/>
      
    </div> -->
    <div class="line12" style="height:1.3rem"></div>

    <div class="content-title" >签约信息</div>
    <div class="content">
      <van-field label="建站时间" :value="pendingInfo.createDate" readonly input-align="right"/>
      <van-field label="提交加盟时间" :value="pendingInfo.signDate" readonly input-align="right" />
      <van-field label="参与人员" :value="pendingInfo.participant" readonly input-align="right"/>
    </div>
    <div class="line12"></div>
    <div class="content-title">图片上传</div>
    <div class="card-upload">
      <img :src="item.filePath" alt="" v-for="(item, index) in signPic" :key="index" @click="imgShowClick(item.filePath)">
    </div >
    <div class="content-desc"></div>

    <div class="line12"></div>
    <div class="content-title ">工作备注</div>
    <van-field class="workNote" v-model="pendingInfo.workNote" rows="4" autosize readonly type="textarea"/>
    <div class="agreement flex" v-if="isShow_Checkpending_Audit">
      <div>站长已签署<span @click="protocolClick('AGT000')">《村口壹站加盟合作协议》</span> </div>
    </div>

    <div class="footer flex" v-if="isShow_Checkpending_Audit">
      <van-button class="notPass" slot="button" plain color="#3F7C53" @click="rejectClick">不通过</van-button>
      <van-button class="pass" slot="button" color="#3F7C53" @click="buttonClick('AGREE')">审核通过</van-button>
    </div>

    <van-overlay :show="show" @click="show = false">
      <div class="overlay-wrapper" @click.stop>
        <div class="block">
          <van-field v-model="comment" rows="4" autosize type="textarea" placeholder="请填写审核不通过原因" maxlength="50"/>
          <div class="flex overlay-button">
            <van-button slot="button" hairline plain color="#B5BBC9" @click="cancelClick">取消</van-button>
            <van-button slot="button" class="sure"  hairline plain color="#B5BBC9" @click="buttonClick('REJECT')">确定</van-button>
          </div>
        </div>

      </div>
    </van-overlay>
    <!--
    <van-overlay :show="imgshow" @click="imgshow = false" style="display:flex; align-items:center">
      <img :src="filePath" style="width:100%"/>
    </van-overlay>
    -->

  </div>
</template>

<script>
import { Icon, Button, Field, Toast, Dialog, Overlay } from 'vant'
import {  completeStep , getContractByNo, getSiteByStationNo,getPicByTypeAndNo,getSXRInfoByStationNo } from '@/utils/api'
import common from '@/utils/common'
import HeaderSiteInfo from "../../components/siteInfo/headerSiteInfo";
import {appAction, uwStatisticAction} from "../../utils/util";
import {getPendingStationByStationNo} from "../../utils/api";
import {mapGetters} from 'vuex'
import {Page_SiteList_Mine} from "../../store/mutation-types";
import {STATION_FIRST_VERIFIED} from "../../utils/constantNum";
export default {
  components:{
    HeaderSiteInfo,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Field.name]: Field,
    [Toast.name]: Toast,
    [Dialog.name]: Dialog,
    [Overlay.name]: Overlay,
  },
  data() {
    return {
      logoImg: require('@/assets/img/logo_f.png'),
      siteBg:require('@/assets/img/site/siteBg.png'),
      kaocha: require('@/assets/img/site/kaocha.png'),
      fileList: [],
      fileNoList:[],
      pendingInfo:{},
      signPic:[],
      show: false,
      comment: '',
      rejectFlag : false,
      imgshow: false,
      filePath: '',
      isSXR:'',
      pageshow: false,
    }
  },
  computed:{
    ...mapGetters([
            'isShow_Checkpending_Audit'
    ])
  },
  methods: {
    imgShowClick(filePath) {
      this.imgshow = true
      this.filePath = filePath
    },
    siteInfoClick() {
      this.$router.push({name:'siteInfo', query: {stationNo: this.pendingInfo.stationNo}})
    },
    protocolClick() {
      getContractByNo({
        electronContractNo: this.pendingInfo.electronContractNo
      }).then(res=>{
        if (res.data.data != '' && res.data.data != undefined){
          window.location.href = res.data.data
        }
      })
    },
    cancelClick() {
      this.show = false
      this.comment = ''
      this.rejectFlag = false
    },
    rejectClick() {
      this.show = true
      this.rejectFlag = true
    },
    buttonClick(approve) {
      if(this.rejectFlag){
        if(this.comment.length < 10 || this.comment.length > 50){
          this.show = true
          Toast('原因必须大于10个字符，小于50个字符')
          return
        }
      }
      completeStep({
        token: common.getItem('wtToken'),
        applyNo: this.$route.query.applyNo?this.$route.query.applyNo:this.pendingInfo.applyNo,
        approve: approve,
        stationTask:'JOINTRIAL',
        comment: approve == 'AGREE' ? '通过' : this.comment
      }).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            Toast('成功')
            if (common.getItem('jumpFrom') != undefined ) {
              appAction(STATION_FIRST_VERIFIED)
            }else {
              this.$router.push({name: 'mysite', query:{role: common.getItem('userRole'),pageType:'home'}})

            }


          }
        }
      })
    },
    getsiteInfo() {
      let para = {
          stationNo: this.$route.query.stationNo
        }
      getSiteByStationNo(para).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            this.pendingInfo = res.data.data
            // if(this.pendingInfo.currStep != 'JOINTRIAL_'){
            //   this.pageshow = false
            //   this.$router.push({name: 'waitingSign', query:{stationNo: this.pendingInfo.stationNo}})
            // }else{
              this.pageshow = true
            // }
            this.pendingInfo.participant = res.data.data.participant.replace(/,/g,' ')
            common.setItem('masterNo', res.data.data.uniqueNo)
          }
        }
      })
    },
    getSXRInfo() {
      let para = {
        stationNo: this.$route.query.stationNo
      }
      getSXRInfoByStationNo(para).then(res=>{
        if(res.code == 200){
          console.log("res:",res)
          if(res.data && res.data.businessCode == 200){
            this.isSXR = res.data.isSXR
          }
        }
      })
    },
    // 获取图片接口，针对页面刷新
    getPic() {
      this.fileList = []
      getPicByTypeAndNo({
        fileType: 'signPic',
        stationNo: this.$route.query.stationNo,
        token: common.getItem('wtToken')
      }).then(res=>{
        this.signPic = []
        for(let i= 0; i< res.data.picList.length; i++){
          this.signPic.push({filePath:''})
        }
        for(let [i,obj] of res.data.picList.entries()){
          if(process.env.NODE_ENV == 'production'){
            this.signPic[i].filePath= window.location.origin + '/file/' + obj.pic  + '?Authorization=' + common.getItem('wtToken')
          }else{
            this.signPic[i].filePath = 'http://124.70.167.116:20081' + '/file/' + obj.pic  + '?Authorization=' + common.getItem('wtToken')
          }
        }
      })
    },
    getPendingStationData(){
      let para = {
        stationNo: this.$route.query.stationNo
      }
      getPendingStationByStationNo(para).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            this.pendingInfo = res.data.data
            this.pendingInfo.participant = res.data.data.participant.replace(/,/g,' ')
            common.setItem('masterNo', res.data.data.uniqueNo)
          }
        }
      })

    }
  },
  mounted() {
    console.log("站點编号：",this.$route.query.stationNo)
    this.$store.dispatch('permission/setPermissionAction',this.$route.query.from )
    if(this.$route.query.stationNo){
      if (this.isShow_Checkpending_Audit){
        this.getPendingStationData()
      }else {
        this.getsiteInfo()
      }
      this.getPic()
      this.getSXRInfo()
    }
    uwStatisticAction('/site/audit','站点初审')

  }
}
</script>

<style lang="less" scoped>
.audit{
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1rem;
  .flex{
    display: flex;
  }
  .overlay-wrapper{
    height: 100%;
    .block{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 6rem;
      background-color: #fff;

      border-radius: 8px;
    }
    .overlay-button{
      .van-button{
        flex:1;
      }
    }
  }
  .kaocha-img{
    width: 1.44rem;
    position: absolute;
    right: 0;
    top: 0rem;
  }
  .seeBtn{
    position: absolute;
    color: #3F7C53;
    background: rgba(63, 124, 83, 0.1);
    right: 0.4rem;
    top: 1rem;
    width:1.44rem;
    height: 0.5rem;
    line-height: 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.24rem;
    text-align: center;
  }
  .top{
    position: relative;
    color: #fff;
    background: #F7F8F9;
    .sitebg-img{
      width: 100%;
    }
    .siteImg-img{
      width: 100%;
    }
    .top-site{
      margin: -1rem auto;
      z-index:1;
      position: relative;
      background: #fff;
      width:6.9rem;
      box-shadow: 0px 18px 26px 0px rgba(0,0,0,0.05);
      border-radius: 16px;
      .top-wrap{
        width: 100%;
        padding: 0.4rem;
        .site-no{
          color: #999999;
          font-size: 0.26rem;
        }
        .station_name{
          color: #333;
          font-size: 0.4rem;
          margin-top: 0.1rem;
          font-weight: 400;
        }
        .station_dept{
          color: #9B9B9B;
          font-size: 0.26rem;
          margin-top: 0.05rem;
        }
      }
      .content-wrap{
        justify-content: space-between;
      }
    }
    .bank-wrap{
      width: 6.9rem;
      background:#F6F9F7;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      padding: 0.24rem 0.4rem;
      .left, .right{
        flex: 1;
        color: #4A4A4A;
        font-size: 0.24rem;
      }
    }
  }
  .title{
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem 0.3rem;
    border-top: 0.1rem solid #3F7C53;
    background: #fff;
    .logo-img{
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.3rem;
    }
    .title_left{
      align-items: center;
    }
    .title_mid{
      margin-left: 0.3rem;
    }
  }
  .content{
    // margin-top: 1.2rem;
  }
  .content-title{
    margin: 15px 0.3rem;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    height: 17px;
    line-height: 16px;
    border-left:2px solid #3F7C53;
    padding-left: 4px;
  }
  .content-desc{
    margin: 0rem 0.3rem;
    font-size: 0.26rem;
    color: #aaa;
    padding: 0 0 12px 0;
  }
  .card-upload{
    margin: 0 0.3rem;
    height: 1.5rem;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 0.2rem;
    overflow: hidden;
    img{
      width: 1.2rem;
      height: 1.2rem;
      border: 1px solid #ddd;
      margin-right: 0.1rem;
    }
  }
  .agreement {
    padding: 38px 12px  30px 12px;
    font-size: 0.28rem;
    background: #F7F8F9;
    div{
      margin-left: 0.1rem;
    }
    span {
      color: #3F7C53;
    }

  }
  .footer{
    position: absolute;
    background: #fff;
    bottom: 0;
    left: 0;
    width: 100%;
    .van-button{
      width: 170px;
      margin: 0.24rem;
    }
    .notPass{
      color: #333 !important;
      margin-right: 0;
      border: 1px solid #E5E5E5 !important;
    }
    // .pass{
    //   margin: 0.24rem;
    // }
  }
  .line12{
    background: #F7F8F9;
    height: 12px;
  }
}
</style>
<style>
  .van-picker__confirm {
    color: #3F7C53;
  }
  .van-picker__cancel{
    color: #3F7C53;
  }
  .auditWarp .van-cell{
    padding: 14px 15px ;
    border-bottom: 1px solid #F7F8F9;
  }
  .auditWarp .van-cell .van-field__label{

    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 500;
    color: #333333;
  } .auditWarp .van-cell .van-cell__value{

    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
  .workNote{
    border-top: 1px solid #EEEEEE;
  }
    .workNote .van-field__control{
      font-size: 17px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
  .auditWarp .van-icon-plus{
   color: #3F7C53;
    font-size: 20px;
    font-weight: bold;

  }
  .auditWarp .card-upload img{
    margin-right: 15px!important;
  }
.auditWarp .van-cell {

  border-radius: 8px;
}
  .auditWarp .block .van-field__control{

    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
  .auditWarp .block .van-button{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .auditWarp .block .sure{
    color:#3F7C53!important; ;
  }

</style>
